import * as amplitude from '@amplitude/analytics-browser'

export const trackPageView = (
	pageUrl: string,
	pageSource: string,
	utmParams: any,
) => {
	const eventProperties = {
		pageUrl,
		pageSource,
		...utmParams,
	}

	if (pageUrl === '/') {
		// Tracking a visit to the home page
		amplitude.track('LandingView', eventProperties)
	} else if (pageUrl === '/stores') {
		// Tracking an exact visit to the stores page
		amplitude.track('stores', eventProperties)
	} else if (pageUrl === '/brands') {
		// Tracking an exact visit to the stores page
		amplitude.track('brandsPage', eventProperties)
	} else if (pageUrl.includes('stores/')) {
		// Tracking a visit to any store page
		amplitude.track('StoreView', eventProperties)
	} else if (pageUrl.includes('-landing')) {
		// Tracking a visit to any landing page
		amplitude.track('LandingView', eventProperties)
	}
}
